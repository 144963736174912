@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

html,
body {
  scroll-behavior: smooth;
  font-family: "PolySans Trial", sans-serif;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.82)),#2603b2; */
}




